<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="sendData">
      <div class="form-page">
        <router-link class="back" :to="{ name: '表單項目' }">
          <eva-icon fill="#FFF" name="corner-up-left-outline"></eva-icon>
        </router-link>
        <div class="form-header">
          每週檢查項目表單<br />
          <span>每週(一)出車前請確實檢查車輛設備狀況</span>
        </div>

        <div class="form-content">
          <div class="page-wrap" v-show="currentPage === 1">
            <div class="page" v-if="currentPage === 1">
              <div class="form-title">表單資訊</div>
              <div class="form-group">
                <label
                  for="inputDriver"
                  class="col-xs-3 col-sm-2 control-label red"
                  >DC別</label
                >
                <div class="col-xs-9 col-sm-10">
                  <input
                    v-model="form.StoreCode"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputPlateNumer"
                  class="col-xs-3 col-sm-2 control-label red"
                  >組別</label
                >
                <div class="col-xs-9 col-sm-10">
                  <input
                    v-model="form.GroupName"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputDate"
                  class="col-xs-3 col-sm-2 control-label red"
                  >車輛保管人</label
                >
                <div class="col-xs-9 col-sm-10">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input
                      v-model="form.DriverAccount"
                      type="text"
                      class="form-control"
                      disabled
                    />
                    <span v-if="errors[0]" class="text-danger ml-1">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputStartMileage"
                  class="col-xs-3 col-sm-2 control-label red"
                  ><span class="text-danger">*</span> 車號</label
                >
                <div class="col-xs-9 col-sm-10">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                   

                    <!-- <el-autocomplete
                      v-model="form.LicensePlateNo"
                      :fetch-suggestions="querySearch"
                      placeholder="請選擇或輸入車號"
                      style="width: 100%"
                      @select="changeCarNumber"
                      :select-when-unmatched="true"
                    ></el-autocomplete> -->
<!-- label="value"
                      track-by="value" -->
                    <multiselect
                      v-model="form.LicensePlateNo"
                      tag-placeholder="新增車號"
                      placeholder="請選擇或輸入新車號"
                      selectLabel="點擊選取"
                      deselectLabel="點擊取消選取"
                      selectedLabel="已選擇"                      
                      :options="carNumList"
                      :multiple="false"
                      :taggable="true"
                      :reset-after="false"
                      @tag="addTag"
                      @select="changeCarNumber"
                    ></multiselect>

                   

                    <span v-if="errors[0]" class="text-danger ml-1">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputStartMileage"
                  class="col-xs-3 col-sm-2 control-label red"
                  ><span class="text-danger">*</span>日期</label
                >
                <div class="col-xs-9 col-sm-10">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <el-date-picker
                      style="width: 100%"
                      :picker-options="pickerOptions"
                      v-model="form.StartDate"
                      type="week"
                      :format="startDate + '~' + endDate"
                      placeholder="選擇週"
                      :disabled="true"
                    >
                      <!-- :disabled="dateDisable"
                    @change="changeRangeDate"
                     -->
                    </el-date-picker>
                    <span v-if="errors[0]" class="text-danger ml-1">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>

          <div
            class="page-wrap"
            v-for="(item, index) of pageList"
            :key="index"
            v-show="currentPage === index + 2"
          >
            <div class="page" v-if="currentPage === index + 2">
              <div class="form-title">{{ item.name }}</div>

              <div
                class="form-group row"
                v-for="(checkItem, index) of item.list"
                :key="checkItem.InspectionItemGuid"
              >
                <label class="col-xs-12 col-sm-6"
                  ><span class="text-danger">*</span>{{ index + 1 }}.{{
                    checkItem.Item
                  }}：{{ checkItem.Standards }}</label
                >

                <div class="col-xs-12 col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="radio-container">
                      <label>
                        <input
                          type="radio"
                          :value="true"
                          :name="checkItem.InspectionItemGuid"
                          v-model="checkItem.IsQualified"
                        />
                        <span
                          ><eva-icon name="checkmark-outline"></eva-icon>合
                          格</span
                        >
                      </label>
                      <label>
                        <input
                          type="radio"
                          :value="false"
                          :name="checkItem.InspectionItemGuid"
                          v-model="checkItem.IsQualified"
                        />
                        <span
                          ><eva-icon name="close-outline"></eva-icon
                          >不合格</span
                        >
                      </label>
                    </div>
                    <span
                      style="display: block; text-align: center"
                      v-if="errors[0]"
                      class="text-danger ml-1"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-12">
                  <div class="flex" v-if="checkItem.IsQualified !== false">
                    備註<input
                      type="text"
                      class="form-control"
                      v-model="checkItem.Notes"
                    />
                  </div>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    v-if="checkItem.IsQualified === false"
                  >
                    <div class="flex">
                      備註<input
                        type="text"
                        class="form-control"
                        v-model="checkItem.Notes"
                      />
                    </div>
                    <span
                      style="display: block; text-align: center"
                      v-if="errors[0]"
                      class="text-danger ml-1"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
              </div>

              <div class="form-group" v-if="index === 3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="flex">
                    <span class="text-danger">*</span>
                    滅火器到期日
                    <el-date-picker
                      v-model="form.ExtinguisherExpiredDate"
                      type="date"
                      placeholder="請選擇日期"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                  <span v-if="errors[0]" class="text-danger ml-1">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group" v-if="index === 3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="flex">
                    <span class="text-danger">*</span>
                    清洗、消毒日期
                    <el-date-picker
                      v-model="form.DisinfectionDate"
                      type="date"
                      placeholder="請選擇日期"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                  <span v-if="errors[0]" class="text-danger ml-1">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="button-wrap">
            <span v-if="currentPage === 1" style="opacity: 0.3"
              ><eva-icon name="chevron-left-outline"></eva-icon>上一步</span
            >
            <span
              v-if="currentPage !== 1"
              style="cursor: pointer"
              @click="currentPage -= 1"
              ><eva-icon name="chevron-left-outline"></eva-icon>上一步</span
            >
            <div>
              <span class="current">{{ currentPage }}</span
              >/5
            </div>
            <button
              v-if="currentPage !== 5"
              style="cursor: pointer"
              type="submit"
            >
              下一步<eva-icon name="chevron-right-outline"></eva-icon>
            </button>
            <span v-if="currentPage === 5" style="opacity: 0.3"
              >下一步<eva-icon name="chevron-right-outline"></eva-icon
            ></span>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-danger btn-save">
          儲存
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { Notification } from "element-ui";
export default {
  data() {
    return {
      user: {},
      currentPage: 1,
      tempData: null,

      form: {
        DriverAccount: "",
        GroupCode: "",
        GroupName: "",
        StoreCode: "",
        LicensePlateNo: null,
        StartDate: null,
        EndDate: null,        
        InspectionItems: [],
        DisinfectionDate: null,
        ExtinguisherExpiredDate: null,
        WeeklyInspectionGuid: null, //有資料的才有
      },
      carNumList: [],

      pageList: [], //用來讓客戶選填的
      clearList: [], //乾淨的
      keepList: [], //有資料的

      pickerOptions: {},

      dateDisable: false,

      //  給日期 foramt 使用
      startDate: null,
      endDate: null,
    };
  },
  watch: {
    "form.StartDate"() {
      const vm = this;

      vm.changeDate()
    },
  },
  methods: {
    goComplete() {
      this.$router.push({ name: "每日檢察項目表單完成" });
    },
    getCheckList() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/GetInspectionItems`;

      console.log("api", api);

      const params = {
        formType: "Weekly",
      };
      //vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api, { params })
        .then((response) => {
          let entireDate = Object.entries(response.data.Data.Weekly);

          entireDate.forEach((item) => {
            this.clearList.push({
              name: item[0],
              list: item[1],
            });
          });

          this.clearList.forEach((item) => {
            item.list = item.list.map((innerItem) => {
              return {
                ...innerItem,
                Notes: "",
                IsQualified: null,
              };
            });
          });

          //this.keepList=[...this.clearList];

          this.keepList = JSON.parse(JSON.stringify(this.clearList));
        })
        .catch((error) => {
          //vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    getCarReason() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/VehicleMaintenance/Maintenance/GetCarTypes`;

      console.log("api", api);

      //vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          //vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    async sendData() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        console.log("111");
        return;
      }
      if (this.currentPage === 5) {
        //const vm = this;
        //const api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/SetWeeklyInspection`;

        this.form.InspectionItems = [];
        // this.form.StartDate = this.dayjs(this.form.StartDate).format(
        //   "YYYY-MM-DD"
        // );
        this.form.EndDate = this.dayjs(this.form.StartDate)
          .add(6, "day")
          .format("YYYY-MM-DD");
        // this.form.ExtinguisherExpiredDate = this.dayjs(this.form.ExtinguisherExpiredDate).format("YYYY-MM-DD");
        // this.form.DisinfectionDate = this.dayjs(this.form.DisinfectionDate).format("YYYY-MM-DD");

        this.pageList.forEach((item) => {
          this.form.InspectionItems.push(...item.list);
        });

        let httpMethod = "";
        let api = "";

        this.form.LicensePlateNo=this.form.LicensePlateNo.toUpperCase();

        //有兩個狀況  如果有guid要用put 沒guid要post
        if (this.form.WeeklyInspectionGuid !== null) {
          httpMethod = "put";
          api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/UpdateWeeklyInspection`;
        } else {
          httpMethod = "post";
          api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/SetWeeklyInspection`;
        }

        const vm = this;

        console.log("this.form", this.form);

        //vm.$store.dispatch("updateLoading", true);
        vm.$http[httpMethod](api, this.form)

          .then((response) => {
            console.log("response", response);
            if (response.data.ErrorMessage === null) {
              this.$router.push({ name: "每日檢察項目表單完成" });
            } else {
              Notification({
                title: "錯誤",
                message: response.data.ErrorMessage,
                type: "error",
                duration: 2000,
              });
            }
          })
          .catch((error) => {
            //vm.$store.dispatch("updateLoading", false);
            Notification({
              title: "錯誤",
              message: error.response.data,
              type: "error",
              duration: 2000,
            });
          });

        //送出
      } else {
        this.currentPage += 1;
      }
    },

    //變更車號時需同時變更DC別 並且跑get api撈出之前寫過的資料
    changeCarNumber(selVal) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/GetWeeklyInspection/${this.user.Account}/${selVal}`;

      //vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          console.log("respresponseresponseresponseonse", response.data.Data);

          const moonStartLanding = new Date(response.data.Data.StartDate);
          const moonEndLanding = new Date(response.data.Data.EndDate);
          // const moonEndLanding = new Date(
          //   this.dayjs(response.data.Data.EndDate)
          //     .add(7, "day")
          //     .format("YYYY-MM-DD")
          // );

          vm.endDate = vm.dayjs(response.data.Data.EndDate).format('YYYY/MM/DD');

          this.pickerOptions = {
            disabledDate(time) {
              return (
                time.getTime() < moonStartLanding.getTime() ||
                time.getTime() > moonEndLanding.getTime()
              );
            },
            firstDayOfWeek: 1,
          };

          if (
            response.data.Data.WeeklyInspectionGuid !==
            "00000000-0000-0000-0000-000000000000"
          ) {
            this.tempData = response.data.Data;

            this.form = {
              ...this.form,
              InspectionItems:
                response.data.Data.TB_CAR_WEEKLY_INSPECTION_DETAILs,
              StartDate: this.dayjs(response.data.Data.StartDate).format(
                "YYYY-MM-DD"
              ),
              LicensePlateNo: response.data.Data.LicensePlateNo,
              
              DisinfectionDate: response.data.Data.DisinfectionDate,
              WeeklyInspectionGuid: response.data.Data.WeeklyInspectionGuid,
            };

            this.keepList.forEach((item) => {
              item.list.map((innerItem) => {
                response.data.Data.TB_CAR_WEEKLY_INSPECTION_DETAILs.find(
                  (dItem) => {
                    if (
                      dItem.InspectionItemGuid === innerItem.InspectionItemGuid
                    ) {
                      innerItem.IsQualified = dItem.IsQualified;
                      innerItem.Notes = dItem.Notes;
                    }
                  }
                );
              });
            });

            this.dateDisable = false;

            //this.pageList = [...this.keepList];
            this.pageList = JSON.parse(JSON.stringify(this.keepList));
          } else {
            this.dateDisable = true;

            this.pageList = JSON.parse(JSON.stringify(this.clearList));

            this.form.DisinfectionDate = null;

            

            this.form.WeeklyInspectionGuid = null;

            this.form.StartDate = this.dayjs(
              response.data.Data.StartDate
            ).format("YYYY-MM-DD");
          }

          if(response.data.Data.ExtinguisherExpiredDate!=='0001-01-01T00:00:00'){            
            this.form.ExtinguisherExpiredDate=response.data.Data.ExtinguisherExpiredDate;
          }else{
            this.form.ExtinguisherExpiredDate=null;
          }
          

        })
        .catch((error) => {
          //vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    //變更日期時，如果不是api給的日期表示是新的需用post
    // changeRangeDate(selVal) {
    //   let compareDate = this.dayjs(selVal).format("YYYY-MM-DD");
    //   let comfirmDate = this.dayjs(this.tempData.StartDate)
    //     .add(1, "day")
    //     .format("YYYY-MM-DD");

    //   if (compareDate !== comfirmDate) {
    //     this.form.WeeklyInspectionGuid = null;
    //     this.form.ExtinguisherExpiredDate = null;
    //     this.form.DisinfectionDate = null;

    //     this.pageList = JSON.parse(JSON.stringify(this.clearList));
    //   } else {
    //     this.form = {
    //       ...this.form,
    //       InspectionItems: this.tempData.TB_CAR_WEEKLY_INSPECTION_DETAILs,
    //       LicensePlateNo: this.tempData.LicensePlateNo,
    //       ExtinguisherExpiredDate: this.tempData.ExtinguisherExpiredDate,
    //       DisinfectionDate: this.tempData.DisinfectionDate,
    //       WeeklyInspectionGuid: this.tempData.WeeklyInspectionGuid,
    //     };

    //     this.pageList = JSON.parse(JSON.stringify(this.keepList));
    //   }
    // },

    // querySearch(queryString, cb) {
    //   var restaurants = this.carNumList;
    //   var results = queryString
    //     ? restaurants.filter(this.createFilter(queryString))
    //     : restaurants;
     
    //   cb(results);
    // },
    // createFilter(queryString) {
    //   return (restaurant) => {
    //     return (
    //       restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
    //       0
    //     );
    //   };
    // },

    addTag (newTag) {
      // const tag = {
      //   value: newTag,
      // }
      this.carNumList.push(newTag);
      this.form.LicensePlateNo=newTag;

      this.changeCarNumber(newTag);
    },

    changeDate() {  //  改變日期的 format 
      const vm = this;

      vm.startDate = vm.dayjs(vm.form.StartDate).format('YYYY/MM/DD');
    },

  },
  created() {
    this.getCarReason();
    this.getCheckList();
    this.user = JSON.parse(localStorage.getItem("user"));

    this.form.DriverAccount = this.user.Account; //這三個不會動
    this.form.GroupCode = this.user.Driver.GroupId; //這三個不會動
    this.form.GroupName = this.user.Driver.Group; //這三個不會動

    if (this.user.Driver.DriverVehicles.length > 0) {
      this.form.StoreCode = this.user.Driver.DriverVehicles[0].STOR_CDX;
      //this.carNumList = [...this.user.Driver.DriverVehicles];
      // this.carNumList=this.user.Driver.DriverVehicles.map(item=>{
      //   return{
      //     value:item.CARX_NOX
      //   }
      // })      

      //找出DRIV_MAN等於駕駛的
      let defaultCar = this.user.Driver.DriverVehicles.find(item=>{
        return item.DRIV_MAN ===this.user.Account;
      });      
      this.form.LicensePlateNo =defaultCar.CARX_NOX;
      this.changeCarNumber(this.form.LicensePlateNo);

      this.carNumList=this.user.Driver.DriverVehicles.map(item=>{
        return item.CARX_NOX
      })      
    } else {
      console.log("沒有車牌");
    }
  },
};
</script>